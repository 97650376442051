import themesConfig from 'app/configs/themesConfig';
import i18n from '../../i18n';

const themeMode = window.localStorage.getItem('theme_mode') || 'light';
const settingsConfig = {
  layout: {
    style: 'layout2', // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || 'ltr', // rtl, ltr
  theme: {
    main: themeMode === 'dark' ? themesConfig.defaultDark : themesConfig.default,
    navbar: themeMode === 'dark' ? themesConfig.defaultDark : themesConfig.defaultDark,
    toolbar: themeMode === 'dark' ? themesConfig.defaultDark : themesConfig.default,
    footer: themeMode === 'dark' ? themesConfig.defaultDark : themesConfig.default,
  },
  themeMode,
  /*
   To make whole app auth protected by default set defaultAuth:['admin','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */
  defaultAuth: null,
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: '/profile',
  turnstileWidgetsKey: process.env.REACT_APP_TURNSTILE_WIDGETS_KEY,
  apiURL: process.env.REACT_APP_API_URL,
  appURL: process.env.REACT_APP_SITE_URL,
  assets: process.env.REACT_APP_ASSETS_BASE_URL,
};

export default settingsConfig;
