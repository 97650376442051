import EnkUtils from '@enk/utils/EnkUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends EnkUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            // err.response.status === 401 &&
            (err.response.status === 401 || err.response.status === 403) &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.axiosLogout();
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'accessToken expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = async (email, password, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          data: {
            email,
            password,
          },
          token,
        })
        .then(async (response) => {
          if (
            response.data.accessToken &&
            response.data.accessToken !== 'undefined' &&
            response.data.accessToken !== 'null' &&
            response.data.accessToken !== null &&
            response.data.accessToken !== undefined &&
            response.data.accessToken !== ''
          ) {
            this.setSession(response.data.accessToken);
            this.getUserProfile()
              .then((user) => {
                this.emit('onLogin', user);
                resolve(user);
              })
              .catch((error) => {
                this.axiosLogout();
                reject(new Error('Failed to login with Email and Password.'));
              });
          } else {
            reject(new Error('Failed to login with Email and Password.'));
          }
        })
        .catch((error) => {
          this.axiosLogout();
          reject(new Error('Failed to login with Email and Password.'));
        });
    });
  };

  signInWithUserNameAndPassword = async (username, password, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          data: {
            username,
            password,
          },
          token,
        })
        .then(async (response) => {
          if (
            response.data.accessToken &&
            response.data.accessToken !== 'undefined' &&
            response.data.accessToken !== 'null' &&
            response.data.accessToken !== null &&
            response.data.accessToken !== undefined &&
            response.data.accessToken !== ''
          ) {
            this.setSession(response.data.accessToken);
            this.getUserProfile()
              .then((user) => {
                this.emit('onLogin', user);
                resolve(user);
              })
              .catch((error) => {
                this.axiosLogout();
                reject(new Error('Failed to login with User Name and Password.'));
              });
          } else {
            reject(new Error('Failed to login with User Name and Password.'));
          }
        })
        .catch((error) => {
          reject(new Error('Failed to login with User Name and Password.'));
        });
    });
  };

  signInWithPhoneAndPassword = async (mobile, password, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          data: {
            mobile,
            password,
          },
          token,
        })
        .then(async (response) => {
          console.log('signInWithPhoneAndPassword', response);
          if (response.data) {
            console.log('setSession', response.data.accessToken);
            this.setSession(response.data.accessToken);
            this.getUserProfile()
              .then((user) => {
                this.emit('onLogin', user);
                resolve(user);
              })
              .catch((error) => {
                this.axiosLogout();
                reject(new Error('Failed to login with Phone and Password.'));
              });
          } else {
            reject(new Error('Failed to login with Phone and Password.'));
          }
        }).catch((error) => {
          reject(new Error('Failed to login with Phone and Password.'));
        });
    });
  };

  signInWithToken = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.accessToken, {
          data: {
            accessToken: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data) {
            this.setSession(response.data.accessToken);
            this.getUserProfile()
              .then((user) => {
                this.emit('onLogin', user);
                resolve(user);
              })
              .catch((error) => {
                this.axiosLogout();
                reject(new Error('Failed to login with token.'));
              });
          } else {
            this.axiosLogout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  getUserProfile = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(jwtServiceConfig.profile);
      return this.transformUserProfileData(response.data);
    } catch (error) {
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  transformUserProfileData = (user) => {
    const result = {
      data: {
        ...user,
        photoURL: 'assets/images/avatars/avatar.jpg',
        shortcuts: ['dashboard', 'spas'],
      },
    };

    if (user.first_name) result.data.displayName = user.first_name;
    if (user.last_name) result.data.displayName += ` ${user.last_name}`;

    return result;
  };

  // eslint-disable-next-line class-methods-use-this
  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  setSession = (accessToken) => {
    console.log('accessToken', accessToken);
    if (accessToken) {
      localStorage.setItem('jwt_access_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      console.log('Success add Authorization Bearer');
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  axiosLogout = () => {
    this.setSession(null);
    this.emit('onAxiosLogout');
  };

  // eslint-disable-next-line class-methods-use-this
  requstNewPassword = async (email, mobile, token) => {
    const response = await axios.post(jwtServiceConfig.resetPassword, {
      email,
      mobile,
      token,
    });
    return response.data;
  };

  // eslint-disable-next-line class-methods-use-this
  checkToken = async (token) => {
    const response = await axios.post(jwtServiceConfig.checkresetToken, {
      token,
    });
    return response.data;
  };

  // eslint-disable-next-line class-methods-use-this
  resetNewPassword = async (options) => {
    const response = await axios.post(jwtServiceConfig.changePassword, options);
    return response.data;
  };

  // eslint-disable-next-line class-methods-use-this
  resendVerificationCode = async (options) => {
    const response = await axios.post(jwtServiceConfig.resendVerificationCode(options.id), options);
    return response.data;
  };

  // eslint-disable-next-line class-methods-use-this
  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  // eslint-disable-next-line class-methods-use-this
  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
